import React from "react";
import blog1Data from "data/elf/sports.json";
import Navbar from "components/Navbar/navbar";
import BlogStanderd from "components/Blog-standerd/blog-standerd";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const Blogs = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;
    console.dir("blog1Data");
    console.dir(blog1Data);
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Our Blogs -demo."
        paragraph="All the most current news and events of our creative team."
      />
      <BlogStanderd blogs={blog1Data} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Blogs Demo - Elfonze Technologies</title>
      <meta key="description" name="description"
        content="Explore a treasure trove of knowledge and insights with our engaging and informative blogs. From expert advice to captivating stories, discover a world of inspiration and discovery in every post." />
    </>
  )
}

export default Blogs;
